@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');

:root {
  --input-color: #99A3BA;
  --input-border: #CDD9ED;
  --input-background: #fff;
  --input-placeholder: #CBD1DC;
  --input-border-focus: #C3AA62;
  --group-color: var(--input-color);
  --group-border: var(--input-border);
  --group-background: #EEF4FF;
  --group-color-focus: #fff;
  --group-border-focus: var(--input-border-focus);
  --group-background-focus: #C3AA62;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

section {
  padding: 100px 0;
}

.section-header {
  font-size: 45px;
  line-height: 45px;
  font-family: Cinzel;
  text-transform: uppercase;
  color: var(--group-background-focus);
  font-weight: 500;
}

hr {
  width: 100px;
  margin: auto;
  margin-top: 20px;
  height: 1px;
}

.blue-hr {
  background: var(--group-background-focus);
}

.white-hr {
  background: #fff;
}

.godrej-logo-right{
  filter: invert(1)
}

/*Navbar */

.nav-item a{
  color: #000;
  font-size: 13px;
  font-weight: 600;
  transition: 0.2s;
  font-family:Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
}

.nav-item a:hover{
  text-decoration: none;
  color: var(--group-background-focus);
  transform: scale(1.5) !important;
}

/* Hero Section */

.hero-section{
  position: relative;
}

.branding-modal{
  position: absolute;
  width: 30%;
  z-index: 999;
  color: #fff;
  background: rgba(0, 0, 0, 0.74);
  padding: 1em;
  border-radius: 10px;
  bottom: 85px;
  left: 30px;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.branding-title-location{
  font-size: 18px;
}

.branding-title-specs{
  font-size: 25px;
}

.branding-title-price{
  font-size: 22px;
}

/* Overview Section */

.overview-wrapper {
  background: var(--group-background-focus);
  position: relative;
}

.overview-absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overview-padding{
  padding-left: 100px;
  padding-right: 100px;
}

.overview-main {
  background: rgba(0,0,0,0.1);
  padding: 1em;
}

.overview-title {
  font-family: Cinzel;
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
}

.border-partial {
  height: 400px;
  position: relative;
  border-bottom: 5px solid rgba(0,0,0,0.1);
  border-top: 5px solid rgba(0,0,0,0.1);
  border-left: 5px solid rgba(0,0,0,0.1);
}

.border-partial-right-top {
  border-left: 7px solid rgba(0,0,0,0.1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 90%;
}

.border-partial-right-bottom {
  border-left: 7px solid rgba(0,0,0,0.1);
  position: absolute;
  top: 90%;
  right: 0;
  bottom: 0;
}

/* Gradient overview Section */

.overview-description{
  background: rgba(240,240,240, 0.4);
}

.gradient-bg {
  background-color: var(--group-background-focus);
  /* background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%); */
  color: #fff;
  padding: 2em 0;
}

.gradient-bg i{
  color: #fff;
  font-size: 30px;
}

.overview-config-title{
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}

.overview-config-desc{
  font-weight: 700;
  font-size:20px;
  margin: 0;
}

/* Floor Plan Section */

.floor-plan-item{
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  padding: 1.5em;
  color: #fff;
  border-radius: 20px;
}

.floor-plan-item-img-wrapper{
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
}

.floor-plan-item .floor-img{
  filter: blur(2px)
}

.floor-plan-item-btn{
  border: unset;
  outline: unset;
  padding: 5px 25px;
  border-radius: 5px;
  background: var(--group-background-focus);
  font-weight: 600;
  color: #fff;
}

.floor-plan-item-btn:hover{
  background: var(--group-background-focus);
}

.floor-plan-item i{
  font-size: 30px;
  color: #3B7294;
}

.floor-plan-item-type{
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.floor-plan-item-desc{
  color: #3B7294;
  font-size: 22px;
  font-weight: 700;
}

.bed-icon{
  width:30px;
}

/* Configurations Section */

.configurations-section {
  background: rgba(240,240,240, 0.4);
}



/* Projects Section */

.project-card {
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  margin-top: 50px;
  padding: 2em 0;
  transition: 0.5s;
}

.project-card:hover {
  margin-top: 30px;
}

.circle {
  display: flex;
  width: 90px;
  height: 90px;
  transition: all 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
}

.circle:hover {
  border: 3px solid #000;
}

.circle {
  border-radius: 100%;
  border: 3px solid #fff;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.circle:hover {
  -webkit-border-radius: 10px 10px 10px 10px;
}

.dot {
  height: 35px;
  width: 35px;
  background-color: rgba(195, 170, 98, 0.8  );
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 25%;
}

.project-item-title {
  font-size: 14px;
  text-align: center;
  color: #000;
  font-weight: 400;
  margin-top: 20px !important;
}

/* Luxurious Amenities */

.luxurious-amenities{
  background: rgba(230, 230, 230, 0.3);
}

.luxurious-amenities-item{
  background-color: #C3AA62;
  padding: 1em 2em;
  border-radius: 20px;
  text-align: center;
  margin-top: 20px;
  height: 100% !important;
}

.luxurious-amenities-item .svg-img{
  filter: brightness(100);
  width: 50px;
}

.luxurious-amenities-item-desc{
  color:#fff;
  font-weight: 700;
  font-size: 15px;
  margin-top: 10px;
}

/* Gallery Section */

.selected {
  color: #fff;
  background-color: var(--group-background-focus);
  border: 1px solid #00867d;
  padding: 0.5em 1.5em;
  font-weight: 600;
  letter-spacing: 2px;
}

.notSelected {
  color: #000;
  background-color: transparent;
  border: unset;
  outline: unset;
  padding: 0.5em 1.5em;
  font-weight: 600;
  letter-spacing: 2px;
}

.display-block{
  display: block !important;
}

.display-none{
  display: none !important;
}

/* Map */

.map-para {
  color: #707070;
}

i {
  color: var(--group-background-focus);
}

/* Address */

.address {
  background: #222222;
  padding: 10px 0;
  color: #fff;
  text-align: center;
}


/* Visit Site Section */

.site-visit-card{
  background: #fff;
  border-radius: 20px;
  margin-top: -80px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
}

.card-form{
  padding: 1.5em 1em;
}

.visit-img-container{
  background: url('https://godrejhousing.in/Tropicalisle/Authorizedchannelpartner/images/footer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.site-visit-form .group { 
  position:relative; 
  margin-bottom:35px; 
}
.site-visit-form input{
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:300px;
  border:none;
  border-bottom:1px solid #757575;
}
.site-visit-form input:focus{ outline:none; }

/* LABEL ======================================= */
.site-visit-form label{
  color:#999; 
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
.site-visit-form input:focus ~ label, input:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color:#5264AE;
}

/* BOTTOM BARS ================================= */
.site-visit-form .bar 	{ position:relative; display:block; width:300px; }
.site-visit-form .bar:before, .bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:#5264AE; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.site-visit-form .bar:before {
  left:50%;
}
.site-visit-form .bar:after {
  right:50%; 
}

/* active state */
.site-visit-form input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.site-visit-form .highlight {
  position:absolute;
  height:60%; 
  width:100px; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
.site-visit-form input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}

/* footer */

footer {
  padding: 10px 0;
  color: #fff;
  background: #000;
  text-align: center;
}

/* Modal */

.form-field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--input-color);
  border: 1px solid var(--input-border);
  background: var(--input-background);
  transition: border 0.3s ease;
}
.form-field::-moz-placeholder {
  color: var(--input-placeholder);
}
.form-field:-ms-input-placeholder {
  color: var(--input-placeholder);
}
.form-field::placeholder {
  color: var(--input-placeholder);
}
.form-field:focus {
  outline: none;
  border-color: var(--input-border-focus);
}

.form-group {
  position: relative;
  display: flex;
  width: 100%;
}

.form-group > span > i {
  color: var(--input-border-focus)
}

.form-group > span,
.form-group .form-field {
  white-space: nowrap;
  display: block;
}
.form-group > span:not(:first-child):not(:last-child),
.form-group .form-field:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.form-group > span:first-child,
.form-group .form-field:first-child {
  border-radius: 6px 0 0 6px;
}
.form-group > span:last-child,
.form-group .form-field:last-child {
  border-radius: 0 6px 6px 0;
}
.form-group > span:not(:first-child),
.form-group .form-field:not(:first-child) {
  margin-left: -1px;
}
.form-group .form-field {
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  width: 1%;
  margin-top: 0;
  margin-bottom: 0;
}
.form-group > span {
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 25px;
  color: var(--group-color);
  background: var(--group-background);
  border: 1px solid var(--group-border);
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.form-group:focus-within > span {
  color: var(--group-color-focus);
  background: var(--group-background-focus);
  border-color: var(--group-border-focus);
}

.form-group:focus-within > span > i {
  color: var(--group-color-focus);
}


.distance-highlight{
  font-size: 35px;
  font-weight: 500;
}

.location-tag{
  color: var(--group-background-focus);
  font-weight: 600;
  font-size: 20px;
  font-family: Cinzel;
}


@media only screen and (max-width: 800px) {

  .branding-modal{
    width: 55%;
    bottom: 0;
    left: 0;
    padding: 0.5em
  }

  .branding-modal > .floor-plan-item-btn{
    font-size: 10px;
    padding: 0.5em 2em;
  }

  .branding-title-location{
    font-size: 12px;
  }
  
  .branding-title-specs{
    font-size: 12px;
  }
  
  .branding-title-price{
    font-size: 12px;
  }

  .nav-item{
    margin-left: 0px;
    padding: 5px;
    border-bottom: 1px solid var(--group-background-focus);
  }

  .nav-item a{
    margin-left: -15px;
  }

  .godrej-logo-wrapper{
    text-align: right;
    border-bottom: unset;
  }

  .section-header{
    font-size: 30px;
  }

  .overview-wrapper {
    background: var(--group-background-focus);
    height: 600px;
  }

  .overview-absolute{
    margin: 50px 0;
  }
  .overview-main {
    background-color: transparent;
  }

  .overview-title{
    font-size: 28px;
  }

  .overview-padding{
    padding-left: 20px;
    padding-right: 20px;
  }

  .border-partial{
    visibility: hidden ;
  }

  .floor-plan-item{
    margin-top: 30px;
  }
}